import EnergyImage from "../../assets/images/energy.png";
import {Faq} from "./Faq";
import {MoreInfo} from "./MoreInfo";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";

const ContentDiv = styled.div`
    max-width: 928px;
    width: 100%;
    margin: 0 auto;
`;

export function Content() {
    return <>
        <ContentDiv className="px-4 pb-10">
            <div className="pt-4 pb-8 md:py-10 text-dark flex flex-col md:flex-row gap-8">
                <div className="w-full md:w-5/12 shrink-0 overflow-hidden rounded-lg">
                    <img src={EnergyImage} alt={"Energy"}
                         className="object-cover object-center w-full h-full"
                    />
                </div>
                <div>
                    <Faq/>
                </div>
            </div>
            <MoreInfo/>
        </ContentDiv>
        <div className="bg-gray-1 py-8 px-16 text-center text-black font-bold">
            <FormattedMessage id="Weitere Informationen zu Energy Metaverse auf der {RadioEnergy}"
                              values={{
                                  "RadioEnergy": <a href="https://energy.ch/" className="font-extrabold underline text-red">Energy Website</a>
                              }}
            />
        </div>
    </>
}
