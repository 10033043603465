import React, {ComponentPropsWithoutRef, PropsWithChildren, ReactNode, useEffect, useState} from "react";
import styled from "styled-components";
import EnergyLogoCircleUrl from "../../assets/images/energy-logo.png";
import {ReactComponent as ErrorIcon} from "../../assets/icons/error.svg";
import {ReactComponent as Error2Icon} from "../../assets/icons/error-2.svg";
import {ReactComponent as OkIcon} from "../../assets/icons/ok.svg";
import {ReactComponent as CloseCircle} from "../../assets/icons/close-circle.svg";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {gameActions, gameSelectors} from "../../app/game.slice";
import {FormattedMessage} from "react-intl";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import {ReactComponent as ChFlag} from "../../assets/icons/ch.svg";

export type DefinedModalProps = Omit<ModalProps, 'children'>;

const PhoneWrapperDiv = styled.div`
input {
    outline: none;
}
`;

const PhoneError = "Whoops! Das ist aber keine Schweizer Nummer ;)";

export function ModalWin(props: DefinedModalProps & {
    playId?: number;
}) {
    const dispatch = useAppDispatch();

    const [phone, setPhoneValue] = useState<string|undefined>(undefined);
    const [phoneError, setPhoneError] = useState<string>("");

    const contactsModalInProgress = useAppSelector(gameSelectors.contactsModalInProgress);
    const contactsModalResult = useAppSelector(gameSelectors.contactsModalResult);

    const next = () => {
        if (contactsModalInProgress) return;
        setPhoneError("");
        if (!phone || !isValidPhoneNumber(phone)) {
            setPhoneError(PhoneError);
            return;
        }
        dispatch(gameActions.sendContactsForm({phone}));
    };

    const close = () => {
        dispatch(gameActions.setContactsModalResult(undefined));
        dispatch(gameActions.setPlayResult(undefined));
    };

    const tryAgain = () => {
        dispatch(gameActions.setContactsModalResult(undefined));
    };

    return <Modal {...props} onClose={close}>
        <div className="flex flex-col justify-center items-center gap-4 px-4 py-8">
            {!contactsModalResult ? <>
                    <img src={EnergyLogoCircleUrl}
                         className="w-12 h-12"
                    />
                    <h2 className="text-red font-extrabold text-4xl"><FormattedMessage id="Glückwunsch!"/></h2>
                    <div className="font-bold text-lg text-center">
                        <FormattedMessage id="Du hast zwei Energy Star Night Tickets gewonnen!"/>
                    </div>
                    <div className="text-sm text-center">
                        <FormattedMessage
                            id="Bitte hinterlasse uns deine Telefonnummer. Du wirst per SMS informiert, wie du deine zwei Tickets beziehen kannst."/>
                    </div>
                    <div className="shrink-0 w-full text-sm pt-8">
                        <div>
                            <FormattedMessage id="Telefonnummer"/>
                        </div>
                        <PhoneWrapperDiv className="flex border-2 border-gray items-center p-2">
                            <ChFlag
                                className="h-4 mr-2"
                            />
                            <PhoneInput
                                country={"CH"}
                                international={true}
                                withCountryCallingCode={true}
                                placeholder=""
                                value={phone}
                                onChange={setPhoneValue}
                                className={"w-full"}
                            />
                        </PhoneWrapperDiv>
                        {phoneError && <div className="text-red-2">
                            <FormattedMessage id={phoneError}/>
                        </div>}
                    </div>
                    <div className="mt-4 w-full">
                        <ModalButton onClick={next}>
                            <FormattedMessage
                                id={!contactsModalInProgress?'Absenden':'Wird geladen'}
                            />
                        </ModalButton>
                    </div>
                </>
            : contactsModalResult.success ?
                <>
                    <OkIcon className="w-12 h-12"/>
                    <h2 className="text-red font-extrabold text-4xl"><FormattedMessage id="Geschaft!"/></h2>
                    <div className="font-bold text-lg text-center">
                        <FormattedMessage id="Vielen Dank fürs Mitspielen!"/>
                    </div>
                    <div className="text-sm text-center">
                        Du solltest in diesen Minuten ein SMS mit Infos zum Einlösen der Tickets erhalten. Bei Fragen wende dich an <a href="mailto:hotline@energy.ch" className="text-red">hotline@energy.ch</a>
                    </div>
                    <div className="mt-8 w-full">
                        <ModalButton onClick={close}>
                            <FormattedMessage id="Weiter"/>
                        </ModalButton>
                    </div>
                </>
            :
                <>
                    <Error2Icon className="w-12 h-12"/>
                    <h2 className="text-red font-extrabold text-4xl"><FormattedMessage id="Huch!"/></h2>
                    <div className="font-bold text-lg text-center">
                        <FormattedMessage id="Da hat was nicht geklappt."/>
                    </div>
                    <div className="text-sm text-center">
                        <FormattedMessage
                            id="Es gab einen Fehler bei der Übermittlung. Bitte versuche es noch einmal.{br}Remember: It’s still early. #WAGMI"
                            values={{br: <br/>}}
                        />
                    </div>
                    <div className="mt-8 w-full">
                        <ModalButton onClick={tryAgain}>
                            <FormattedMessage id="Nochmal versuchen"/>
                        </ModalButton>
                    </div>
                </>
            }
        </div>
    </Modal>
}

export function ModalButton (props: ComponentPropsWithoutRef<"button">) {
    return <button className="block bg-red-2 text-white text-center p-2 w-full"
        {...props}
    >
        {props.children}
    </button>
}

export function ModalLooseNoTokens(props: DefinedModalProps) {
    const dispatch = useAppDispatch();
    const close = () => {
        dispatch(gameActions.setPlayResult(undefined));
    };
    return <Modal {...props} onClose={close}>
        <div className="flex flex-col justify-center items-center gap-4 px-4 py-8">
            <Error2Icon className="w-12 h-12"/>
            <h2 className="text-red font-extrabold text-4xl"><FormattedMessage id="Oh jeh..."/></h2>
            <div className="font-bold text-lg text-center">
                <FormattedMessage id="Leider hast du keine Tokens mehr"/>
            </div>
            <div className="text-sm text-center">
                <FormattedMessage
                    id="Du bist leider out of Tokens. Aber vielen Dank fürs Mitspielen! Komm doch bald wieder im Energy Metaverse Studio vorbei, wir haben noch viele weitere Überraschungen geplant."
                    values={{br:<br/>}}
                />
            </div>
            <div className="mt-8 w-full">
                <ModalButton onClick={close}>
                    <FormattedMessage id="Weiter"/>
                </ModalButton>
            </div>
        </div>
    </Modal>
}

export function ModalLoose(props: DefinedModalProps) {
    return <ModalLooseInner>
        <div className="italic font-semibold text-center">
            <FormattedMessage id="Leider kein treffer. Versuche es gleich nochmal" />
        </div>
    </ModalLooseInner>
}

export function ModalError(props: DefinedModalProps & {error: string}) {
    return <ModalLooseInner>
        <div className="italic font-semibold text-center">
            <FormattedMessage id="Error: {error}"
                              values={{error: props.error}}
            />
        </div>
    </ModalLooseInner>
}

function ModalLooseInner(props: {children: ReactNode}) {
    const dispatch = useAppDispatch();
    const close = () => {
        dispatch(gameActions.setPlayResult(undefined));
    };
    return <div className="absolute w-full flex justify-center items-center top-16"
                onClick={close}
    >
        <div className="flex flex-col justify-center items-center gap-4
            text-white bg-red overflow-hidden rounded
            p-4" style={{maxWidth: "90%"}}>
            <ErrorIcon className="w-12 h-12" />
            {props.children}
        </div>
    </div>
}

type ModalProps = {
    children: React.ReactNode;
    show: boolean;
    onClose?: ()=>void
}

const ModalInnerDiv = styled.div`
    max-width: 90%;
    width: 512px;
`;

export function Modal(props: ModalProps) {
    const [show, setShow] = useState<boolean>(false);

    useEffect(()=>{
        const to = setTimeout(()=>setShow(props.show), 300);
        return () => clearTimeout(to);
    }, [props.show]);

    const close = () => {
        props.onClose?.();
    }

    return (props.show||show)?<div className={`relative z-10`}>
        <div className={`fixed inset-0 bg-dark transition-opacity
            ${(!props.show||!show)?'opacity-0':'opacity-75'}`}
        />
        <div className={`fixed inset-0 z-10 flex justify-center items-center`}
             onClick={close}
        >
            <ModalInnerDiv className={`overflow-hidden rounded-sm bg-white shadow-xl px-5 py-5 relative
                   transition-all ${(!props.show||!show)?'opacity-0':'opacity-100'}            
            `}
                           onClick={e=>e.stopPropagation()}
            >
                {close&&<div className="absolute top-4 right-4 z-10">
                    <CloseCircle className="w-8 h-8 cursor-pointer" onClick={close}/>
                </div>}
                {props.children}
            </ModalInnerDiv>
        </div>
    </div>:<div/>;
}
