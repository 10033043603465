import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface NavState {
    showSection?: string;
}

const initialState: NavState = {

};

export const navSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        setShowSection: (state, action: PayloadAction<string|undefined>) => {
            state.showSection = action.payload;
        }
    }
})

const w = (state: RootState): NavState => state.nav;

export const navSelectors = {
    showSection: (state: RootState) => w(state).showSection
}

export const navActions = navSlice.actions;

