import React from 'react';
import Header from "./components/top-section/Header";
import {Content} from "./components/content/Content";
import {EthConnector} from "./components/eth/EthConnector";

export default function App () {
    return <div>
        <EthConnector/>
        <Header/>
        <Content/>
    </div>;
}
