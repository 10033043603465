import {ReactElement, ReactNode} from "react";
import {IntlProvider} from "react-intl";
import translations from "./translations.json";

export interface TranslationProviderProps {
    children: ReactNode;
}

export const DEFAULT_LANGUAGE = 'de';
export const LANGUAGES = ['de', 'fr', 'it'] as const;
export type Language = (typeof LANGUAGES)[number];

type Translations = Record<string, string>;

const translationsMap: Record<Language, Translations> = {
    'de': getTranslations('de'),
    'fr': getTranslations('fr'),
    'it': getTranslations('it'),
}

export function getTranslations(language: Language): Translations  {
    return Object.keys(translations).reduce(
        (acc, key) => ({...acc, [key]: (translations as any)[key][language]}),
        {} as Translations
    );
}

export function TranslationProvider(props: TranslationProviderProps): ReactElement {
    const language = DEFAULT_LANGUAGE;
    return <IntlProvider locale={`${language}-CH`} messages={translationsMap[language]}>
        {props.children}
    </IntlProvider>
}