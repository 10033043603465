import {Menu} from "./Menu";
import {TopInfo} from "./TopInfo";
import {FormattedMessage} from "react-intl";


export default function Header() {
    return <div>
        <div className="bg-dark-2">
            <Menu />
            <TopInfo />
        </div>
        <div className="px-4 py-4 text-center">
            <FormattedMessage id="*Wir unterstützen Ethereum Blockchain fähige Wallets, wie z.B. {metamask}"
                              values={{
                                  metamask: <a href="/metamask">Metamask</a>
                              }}
            />
        </div>
    </div>
}

