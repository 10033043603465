import {FormattedMessage} from "react-intl";


export function Faq() {
    return <div>
        <h1 className="font-bold text-xl">
            <FormattedMessage id="Gewinn einlösen" />
        </h1>
        <div className="pt-2 text-lg">
            Du brauchst eine Schweizer Mobiltelefonnummer. Auf diese Nummer senden wir anschliessend ein SMS mit weiteren Informationen zum Bezug der Tickets.
        </div>
        <h1 className="pt-8 font-bold text-xl">
            <FormattedMessage id="Tickets beziehen"/>
        </h1>
        <div className="pt-2 text-lg">
            Du brauchst ein Login bei <a href="https://energy.ch" target="_blank">energy.ch</a>. Wenn du noch keines hast, erstelle eines und verwende die gleiche Mobiltelefonnummer wie vorher. Nach dem Einloggen siehst du den Gewinn in deinem Profil und kannst die Tickets aktivieren.
        </div>
    </div>;
}