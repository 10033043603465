import {useAppSelector} from "../../app/hooks";
import {gameSelectors} from "../../app/game.slice";
import {ReactComponent as LogoShort} from "../../assets/images/logo-short.svg";
import {ReactComponent as LogoWide} from "../../assets/images/logo-wide.svg";
import {FormattedMessage} from "react-intl";
import {ReactNode} from "react";
import styled from "styled-components";
import {WalletButton} from "./common";
import {shortenAccountNumber} from "../../eth/ethUtils";

const HeaderDivWrapper = styled.div`
    box-shadow: 0 25px 25px rgba(146, 146, 146, .1);
`;

const HeaderDiv = styled.div`
    width: 100%;
    max-width: 928px;
    margin: 0 auto;
`;

export function Menu() {
    const user = useAppSelector(gameSelectors.user);

    return <HeaderDivWrapper>
        <HeaderDiv className="flex gap-4 pt-8 pb-4 px-8 justify-between items-center">
            <div>
                <a href="https://energy.ch">
                    <LogoShort className="sm:hidden"/>
                    <LogoWide className="hidden sm:block"/>
                </a>
            </div>

            {!user && <WalletButton className="text-dark bg-white"/>}
            {user && <div className="text-white rounded-full border border-white px-8 py-0.5">{shortenAccountNumber(user.walletAddress)}</div>}
        </HeaderDiv>
    </HeaderDivWrapper>;
}

type MenuLinkProps = {
    children: ReactNode;
}

function MenuLink(props: MenuLinkProps) {
    return <div className="cursor-pointer">
        {props.children}
    </div>
}

