import {DefinedModalProps, Modal} from "../modal/Modal";
import provider_cbw from "../../assets/providers/cbw.png";
import provider_mm from "../../assets/providers/mm.png";
import provider_wc from "../../assets/providers/wc.png";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {FormattedMessage} from "react-intl";
import {ConnProvider} from "../../eth/connectors";


export function ConnectorModal(props: DefinedModalProps & {
    onProviderSelected: (provider: ConnProvider)=>void
}) {
        const providers = [
        {
            image: provider_cbw,
            name: "coinbaseWallet",
            title: "Coinbase Wallet",
        },
        {
            image: provider_wc,
            name: "walletConnect",
            title: "Wallet Connect",
        },
        {
            image: provider_mm,
            name: "injected",
            title: "Metamask",
        }
    ];
    const close = () => props.onClose?.()
    const setProvider = (type: ConnProvider) => {
        props.onProviderSelected(type);
        close();
    };

    return <Modal show={props.show} onClose={close}>
        <div className="relative">
            <h2><FormattedMessage id="Select wallet" /></h2>
            <div>
                {providers.map(provider=>
                    <div className="flex items-center gap-2
                    rounded-lg border-gray mt-2 border-2 overflow-hidden px-2 py-2
                    cursor-pointer hover:border-black
                    "
                         key={provider.name}
                         onClick={()=>setProvider(provider.name as ConnProvider)}>
                        <img src={provider.image} className={"w-5 h-5 shrink-0"}/>
                        <label>{provider.title}</label>
                    </div>
                )}
            </div>
        </div>
    </Modal>;
}

