import {useWeb3React} from "@web3-react/core";
import {connectors, ConnProvider} from "../../eth/connectors";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {gameActions, gameSelectors, MESSAGE_TO_SIGN} from "../../app/game.slice";
import {ConnectorModal} from "./ConnectorModal";
import {useEffect} from "react";
import {switchNetwork} from "../../eth/ethUtils";
import {ethers} from "ethers";
import Web3 from "web3";


export const EthConnector = () => {
    const dispatch = useAppDispatch();
    const isConnectorOpen = useAppSelector(gameSelectors.isConnectorOpen);

    useEffect(()=>{
        dispatch(gameActions.restoreUserAuth());
    }, []);

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();

    const close = () => dispatch(gameActions.setConnectorOpen(false));

    const onProvider = async (provider: ConnProvider) => {
        const providerConn = connectors[provider];
        await deactivate();
        try {
            console.log('activate', providerConn);
            const activationResult = await activate(providerConn);
            console.log('activation result', activationResult);
        } catch (e) {
            console.log('activation error', e);
        }
    };

    useEffect(()=>{
        if (!account) {
            return;
        }
        if (!library) return;
        if (chainId != 1) {
            switchNetwork(library, 1);
            return;
        }

        library.provider.enable();
        const web3 = new ethers.providers.Web3Provider(library.provider);
        const signer = web3.getSigner();

        //const provider = new ethers.providers.Web3Provider((window as any).ethereum)
        //const signer = provider.getSigner()
        signer.signMessage(MESSAGE_TO_SIGN).then((sign: string) => {
            dispatch(gameActions.initializeUser({
                walletAddress: account, sign
            }))
        }).catch((err: any) => {
            console.error(err);
        })
    }, [account, chainId, library]);

    return <ConnectorModal
        onClose={close}
        onProviderSelected={onProvider}
        show={isConnectorOpen}
    />

};
