import EnergyImage from "../../assets/images/energy-2.png";
import {FormattedMessage} from "react-intl";

export function MoreInfo() {
    return <div className="bg-dark rounded-lg w-full overflow-hidden
        flex flex-col md:flex-row
    ">
        <div className="w-full md:w-4/12 shrink-0">
            <img src={EnergyImage}
                 className="object-cover object-center w-full h-full"
            />
        </div>
        <div className="pt-4 pb-8 px-4 md:px-8 text-white">
            <h1 className="font-bold text-xl pt-4">
                <FormattedMessage id="Besuche das Energy Metaverse Radiostudio"/>
            </h1>
            <div className="py-4 text-sm">
                <FormattedMessage id="more_info_block"
                                  values={{br: <br/>}}
                />
            </div>
            <a href="https://energy.ch/metaverse">
                <button className="bg-red-2 rounded-full h-8 text-sm font-semibold px-8 cursor-pointer">
                    <FormattedMessage id="Mehr Infos" />
                </button>
            </a>
        </div>
    </div>
}