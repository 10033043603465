import {useAppSelector} from "../../app/hooks";
import {gameSelectors} from "../../app/game.slice";
import {FormattedMessage} from "react-intl";
import {WalletButton} from "./common";
import {Buzzer} from "./Buzzer";


export function TopInfo() {
    const user = useAppSelector(gameSelectors.user),
        userNotFound = useAppSelector(gameSelectors.userNotFound);

    return <div className="flex flex-col gap-5 py-14">
        <div className={"text-center text-red text-sm md:text-lg"}>
            <FormattedMessage id="Mit Radio Energy ins Metaverse"/>
        </div>
        <div className={"text-center text-white font-extrabold text-xl md:text-3-1/2-xl"}>
            <FormattedMessage id="Gewinne Energy Star Night Tickets!" />
        </div>
        <div className={"text-center text-white text-sm md:text-lg"}>
            <FormattedMessage
                id="Du hast SHOAPs im Radio Energy Metaverse Studio gesammelt? Dann verbinde dein Wallet und aktiviere den Buzzer."
                values={{
                    br: <br/>
                }}
            />
        </div>
        <div className="relative">
            <div className="flex justify-center absolute w-full">
                {!user&&
                    <WalletButton className={"bg-red hover:bg-red-4 h-12 text-base md:h-14 text-white"}/>
                }
            </div>
        </div>
        <div>
            <Buzzer/>
        </div>
        {!user&&<div className={"text-center text-white italic"}>
            {userNotFound ?
                <FormattedMessage id={"{tokens} Tokens zum verbuzzen!"}
                                  values={{tokens: 0}}
                />
                :
                <FormattedMessage id="Noch keine Wallet verbunden"/>
            }
        </div>}
        {user&&<div className={"text-center text-white italic"}>
            <FormattedMessage id={user.tokens==1?
                "{tokens} Token zum verbuzzen!"
                :"{tokens} Tokens zum verbuzzen!"}
              values={{tokens: user.tokens}}
            />
        </div>}
    </div>;
}
