import {ReactComponent as WalletImg} from "../../assets/icons/wallet.svg";
import {FormattedMessage} from "react-intl";
import React from "react";
import {useAppDispatch} from "../../app/hooks";
import {gameActions} from "../../app/game.slice";

export type WalletButtonProps = {
    className: string
};

export function WalletButton(props: WalletButtonProps) {
    const dispatch = useAppDispatch();
    const openSelector = () => dispatch(gameActions.setConnectorOpen(true));

    return <button className={`flex gap-2 items-center text-base 
        px-5 rounded-full h-8 ${props.className}`}
        onClick={openSelector}
    >
        <WalletImg className="w-5 h-5"/>
        <FormattedMessage id="Wallet verbinden" />
    </button>
}