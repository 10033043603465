import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {store} from './app/store';
import {Provider} from "react-redux";
import "./index.sass";
import {TranslationProvider} from "./app/translation";
import {Api} from "./api/service";
import {ethers} from "ethers";
import {Web3ReactProvider} from "@web3-react/core";


// Disable missing translation message as translations will be added later.
// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
    if (message.toString().includes('MISSING_TRANSLATION')
        || (args.length > 1 && args[1].toString().includes('MISSING_TRANSLATION'))
    ) {
        return;
    }
    consoleError(message, ...args);
};

//Api.apiUrl = (typeof process!=="undefined"?process.env?.API_URL:null) || "http://localhost/api";
Api.apiUrl = "/api";

const getLibrary = (provider: any) => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <TranslationProvider>
                <Provider store={store}>
                    <App/>
                </Provider>
            </TranslationProvider>
        </Web3ReactProvider>
    </React.StrictMode>
);