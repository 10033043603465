import RiveBuzz from "../../assets/nrj_buzz_02.riv";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {gameActions, gameSelectors, GameState} from "../../app/game.slice";
import { ModalError, ModalLoose, ModalLooseNoTokens, ModalWin} from "../modal/Modal";
import {Alignment, Fit, Layout, Rive, StateMachineInput} from "@rive-app/canvas";


const RiveDiv = styled.div`
    height: 250px;
    cursor: pointer;
`;
const StateMachineName = "State Machine 1";
const ArtBoardName = "New Artboard";
const states = [
    'wallet_connected',
    'buzz',
    'win',
    'loose',
    'restart',
    'allowedToBuzz',
] as const;
type State = (typeof states)[number];


export function Buzzer() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(gameSelectors.user),
        isPlayInProgress = useAppSelector(gameSelectors.isPlayInProgress),
        playResult = useAppSelector(gameSelectors.playResult);

    const [riveInstance, setRiveInstance] = useState<Rive|null>(null),
        canvasRef = useRef<HTMLCanvasElement|null>(null);

    const [focus, setFocus] = useState<number>(0);

    const [playResultModal, setPlayResultModal] = useState<GameState['playResult']>(undefined);

    useEffect(()=>{
        if (!playResult) {
            setPlayResultModal(playResult);
            return;
        }
        let to = setTimeout(()=>{
            setPlayResultModal(playResult);
        }, 2000);
        return () => {
            clearTimeout(to);
        }
    }, [playResult]);

    useEffect(() => {
        if (!canvasRef.current) return;
        console.log("riveInstance init");
        const riveInstance = new Rive({
            src: RiveBuzz,
            stateMachines: StateMachineName,
            artboard: ArtBoardName,
            autoplay: false,
            animations: "idle_closed",
            canvas: canvasRef.current,
            layout: new Layout({
                fit: Fit.FitHeight,
                alignment: Alignment.Center,
            }),
            onLoad: () => {
                console.log('onLoad');
                riveInstance.resizeDrawingSurfaceToCanvas();
                riveInstance.play();
                setRiveInstance(riveInstance);
            },
            onStateChange: event => {
                // @ts-ignore
                console.log(`state change: ${event.type} ${event.data[0]} ${new Date().toISOString()}`);
            }
        })
    }, [focus]);

    const stateByName = (name: State): StateMachineInput|null => {
        if (!riveInstance) return null;
        const inputs = riveInstance.stateMachineInputs(StateMachineName);
        return inputs.find(i => i.name === name) || null;
    };

    useEffect(() => {
        if (!user || !riveInstance) return;
        const wallet_connected = stateByName("wallet_connected"),
            allowedToBuzz = stateByName("allowedToBuzz");

        console.log(`INIT ${focus}`);
        if (wallet_connected && allowedToBuzz) {
            console.log('fire initialization');
            riveInstance!.play();
            wallet_connected.value = true;
            allowedToBuzz.value = true;
        }
    }, [user?.walletAddress, riveInstance]);

    useEffect(()=>{
        if (!playResult) return;
        if (playResult.win) {
            console.log(`fire win `, stateByName('win'));
            stateByName('win')?.fire();
        } else {
            console.log(`fire loose `, stateByName('loose'));
            stateByName('loose')?.fire();
            if (playResult.error) {
                console.error(playResult.error);
            } else if (playResult.noTokensLeft) {
                console.log('no tokens left');
            }
        }
    }, [playResult, riveInstance]);

    // useEffect(()=>{
    //     const ev = () => {
    //         setFocus(f=>f+1);
    //         console.log('inc focus');
    //     };
    //     window.addEventListener("focus", ev);
    //     return () => {
    //         window.removeEventListener("focus", ev);
    //     }
    // }, []);

    const click = () => {
        if (!user || isPlayInProgress || playResultModal != playResult) return;
        console.log(`fire buzz`);
        stateByName('allowedToBuzz')!.value = true;
        stateByName('buzz')!.fire();
        dispatch(gameActions.play());
    };

    return <>
        <div className="relative w-full">
            {playResultModal ?
                playResultModal.win ?
                    <ModalWin show={true}
                              playId={playResultModal.playId}/>
                    : playResultModal.noTokensLeft ?
                    <ModalLooseNoTokens show={true} />
                    : !playResultModal.error ?
                        <ModalLoose show={true} />
                        :
                        <ModalError show={true} error={playResultModal.error}/>
                :
                <div/>
            }
        </div>
        <div className="flex justify-center">
            <RiveDiv onClick={click} className="select-none">
                <canvas ref={canvasRef}
                        className="h-full"
                />
            </RiveDiv>
        </div>
    </>;
}

/*export function BuzzerOld() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(gameSelectors.user),
        isPlayInProgress = useAppSelector(gameSelectors.isPlayInProgress),
        playResult = useAppSelector(gameSelectors.playResult);

    const [playResultModal, setPlayResultModal] = useState<GameState['playResult']>(undefined);

    const riveRef = useRef<Rive|null>(null);

    useEffect(()=>{
        if (!playResult) {
            setPlayResultModal(playResult);
            return;
        }
        let to = setTimeout(()=>{
            setPlayResultModal(playResult);
        }, 1000);
        return () => {
            clearTimeout(to);
        }
    }, [playResult]);

    const {
        rive,
        RiveComponent: RiveComponentPlayback
    } = useRive({
        src: RiveBuzz,
        stateMachines: StateMachineName,
        artboard: ArtBoardName,
        autoplay: true,
        layout: new Layout({
            fit: Fit.FitWidth,
            alignment: Alignment.Center,
        }),
    });

    riveRef.current = rive;

    const statesMap: Record<string, StateMachineInput | null> = {};
    for (let s of states) {
        statesMap[s] = useStateMachineInput( // eslint-disable-line react-hooks/rules-of-hooks
            rive, StateMachineName, s
        )
    }

    useEffect(() => {
        if (!user) return;
        if (statesMap["wallet_connected"]) {
            statesMap["wallet_connected"].value = true;
            statesMap['allowedToBuzz']!.value = true;
        }
    }, [user?.walletAddress, rive]);

    useEffect(()=>{
        if (!playResult) return;
        if (playResult.win) {
            statesMap['win']?.fire();
        } else {
            statesMap['loose']?.fire();
            if (playResult.error) {
                console.error(playResult.error);
            } else if (playResult.noTokensLeft) {
                console.log('no tokens left');
            }
        }
    }, [playResult, rive]);

    useEffect(()=>{
        const ev = () => {
            riveRef.current?.reset();
            console.log('rive reset');
            console.log(riveRef.current);
        };
        window.addEventListener("focus", ev);
        return () => {
            window.removeEventListener("focus", ev);
        }
    }, []);

    console.log('rive in body');
    console.log(rive);
    console.log('statesMap');
    console.log(statesMap);

    const click = () => {
        if (!user || isPlayInProgress || playResultModal != playResult) return;
        statesMap['allowedToBuzz']!.value = true;
        statesMap['buzz']!.fire();
        dispatch(gameActions.play());
    };

    return <>
        <div className="relative w-full">
            {playResultModal ?
                playResultModal.win ?
                    <ModalWin show={true}
                              playId={playResultModal.playId}/>
                    : playResultModal.noTokensLeft ?
                        <ModalLooseNoTokens show={true} />
                    : !playResultModal.error ?
                        <ModalLoose show={true} />
                        :
                        <ModalError show={true} error={playResultModal.error}/>
                :
                <div/>
            }
        </div>
        <div className="flex justify-center">
            <RiveDiv onClick={click} className="select-none">
                <RiveComponentPlayback/>
            </RiveDiv>
        </div>
    </>;
}*/
