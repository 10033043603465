import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Api, getError} from "../api/service";


export interface User {
    walletAddress: string;
    tokens: number;
}

export interface GameState {
    user?: User;
    userNotFound?: boolean;
    connectorOpen: boolean;
    playInProgress: boolean;
    playResult?: {
        win: true;
        playId: number;
    } | {
        win: false;
        noTokensLeft: boolean;
        error?: string;
    };
    contactsModalInProgress?: boolean;
    contactsModalResult?: {
        success: boolean;
        error?: string;
    };
}

const initialState: GameState = {
    connectorOpen: false,
    playInProgress: false,
};

const payloadSetter = <TKey extends keyof GameState>(target: TKey) =>
    (state: GameState, action: PayloadAction<GameState[TKey]>) => {
        state[target] = action.payload;
    };

export const gameSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setConnectorOpen: payloadSetter("connectorOpen"),
        setUser: (state, action: PayloadAction<GameState['user']>) => {
            state.user = action.payload;
            state.userNotFound = false;
        },
        setUserNotFound: payloadSetter("userNotFound"),
        setPlayInProgress: payloadSetter("playInProgress"),
        setPlayResult: payloadSetter("playResult"),
        setContactsModalInProgress: payloadSetter("contactsModalInProgress"),
        setContactsModalResult: payloadSetter("contactsModalResult"),
    }
});

const w = (state: RootState): GameState => state.game;

export const gameSelectors = {
    user: (state: RootState) => w(state).user,
    userNotFound: (state: RootState) => w(state).userNotFound,
    isConnectorOpen: (state: RootState) => w(state).connectorOpen,
    isPlayInProgress: (state: RootState) => w(state).playInProgress,
    playResult: (state: RootState) => w(state).playResult,
    contactsModalInProgress: (state: RootState) => w(state).contactsModalInProgress,
    contactsModalResult: (state: RootState) => w(state).contactsModalResult,
}

export const MESSAGE_TO_SIGN = "ENERGY BUZZER";

const initializeUser = createAsyncThunk(
    "initializeUser",
    async (
        {walletAddress, sign}: {walletAddress: string, sign: string},
        {dispatch}
    ) => {
        dispatch(gameSlice.actions.setUser(undefined));
        const api = Api.get();
        try {
            const resp = await api.post("?action=auth", {
                "walletAddress": walletAddress,
                "sign": sign
            }) as {token: string};
            dispatch(initializeUserByToken(resp.token));
        } catch (e) {
            console.error(e);
        }
    }
);

const initializeUserByToken = createAsyncThunk(
    "initializeUserByToken",
    async (token: string, {dispatch}) => {
        dispatch(gameSlice.actions.setUser(undefined));
        const api = Api.get();
        api.setToken(token);
        try {
            const resp = await api.get("?action=me") as {address: string, tokens: number};
            dispatch(gameSlice.actions.setUser({walletAddress: resp.address, tokens: resp.tokens}));
        } catch (e) {
            const resp = getError(e);
            if (resp.status === 404) {
                dispatch(gameSlice.actions.setUserNotFound(true));
            } else {
                console.error(e);
            }
            api.setToken(undefined);
        }
    }
);

const restoreUserAuth = createAsyncThunk(
    "restoreUserAuth",
    async (_: void, {dispatch}) => {
        const api = Api.get();
        const savedToken = api.getSavedToken();
        if (!savedToken) return;
        dispatch(initializeUserByToken(savedToken));
    }
);

const play = createAsyncThunk(
    "play",
    async (_: void, {dispatch, getState}) => {
        const api = Api.get();
        dispatch(gameSlice.actions.setContactsModalResult(undefined));
        dispatch(gameSlice.actions.setPlayInProgress(true));
        const user = gameSelectors.user(getState() as RootState);
        dispatch(gameSlice.actions.setPlayResult(undefined));
        if (!user) return;
        try {
            const resp = await api.get("?action=play") as {result: string, tokensLeft: number, playId?: number};
            dispatch(gameSlice.actions.setUser({
                ...user,
                tokens: resp.tokensLeft
            }));
            if (resp.result === "win") {
                dispatch(gameSlice.actions.setPlayResult({
                    win: true,
                    playId: resp.playId!
                }));
            } else if (resp.result === "loose") {
                dispatch(gameSlice.actions.setPlayResult({
                    win: false,
                    noTokensLeft: false
                }));
            } else if (resp.result === "no_tokens_left") {
                dispatch(gameSlice.actions.setPlayResult({
                    win: false,
                    noTokensLeft: true
                }));
            }
        } catch (e) {
            dispatch(gameSlice.actions.setPlayResult({
                win: false,
                error: (e as any).toString(),
                noTokensLeft: false
            }));
        } finally {
            dispatch(gameSlice.actions.setPlayInProgress(false));
        }
    }
);

const sendContactsForm = createAsyncThunk(
    "sendContactsForm",
    async (
        {phone}: {phone: string},
        {dispatch, getState}
    ) => {
        const state = getState() as RootState;
        const playResult = gameSelectors.playResult(state),
            user = gameSelectors.user(state);

        if (!playResult || !playResult.win || !user) {
            console.log(1);
            return;
        }

        dispatch(gameSlice.actions.setContactsModalInProgress(true));
        try {
            const api = Api.get();
            const resp = await api.post("?action=send", {
                playId: playResult.playId,
                phone: phone
            }) as {success: boolean};
            dispatch(gameSlice.actions.setContactsModalResult({
                success: resp.success,
            }))
        } catch (e) {
            dispatch(gameSlice.actions.setContactsModalResult({
                success: false,
                error: (e as any).toString()
            }));
        } finally {
            dispatch(gameSlice.actions.setContactsModalInProgress(false));
        }
    }
)

const logoutUser = createAsyncThunk(
    "logoutUser",
    async (_: void, {dispatch}) => {
        dispatch(gameSlice.actions.setUser(undefined));
        const api = Api.get();
        api.setToken(undefined);
    }
);

export const gameActions = {
    ...gameSlice.actions,
    initializeUser,
    restoreUserAuth,
    logoutUser,
    play,
    sendContactsForm
};
