

export class Api {
    static apiUrl: string;
    static instance: Api;
    static get(): Api {
        if (!this.instance) {
            this.instance = new Api(this.apiUrl);
        }
        return this.instance;
    }

    url: string;
    token?: string;
    private constructor(url: string){
        this.url = url;
    }
    private getUrl(path: string): string {
        return this.url + "/" +path;
    }
    private getHeaders(): HeadersInit {
        const headers: HeadersInit = {};
        if (this.token) {
            headers["Authorization"] = `JWT ${this.token}`;
        }
        headers["Content-Type"] = "application/json";
        return headers;
    }
    private getOptions(): any {
        return {
            headers: this.getHeaders(),
        }
    }
    setToken(token?: string) {
        this.token = token;
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
    }
    getSavedToken(): string|undefined {
        return localStorage.getItem("token") || undefined;
    }
    handleResponse(response: Response) {
        if (response.ok) {
            return response.json();
        }
        return response.json().then(r=>{
            throw new Error(JSON.stringify({status: response.status, response: r}))
        });
    }
    get<T>(path: string): Promise<T> {
        return fetch(
            this.getUrl(path),
            this.getOptions()
        ).then(this.handleResponse);
    }
    post<T>(path: string, body: any): Promise<T> {
        return fetch(
            this.getUrl(path),
            {
                ...this.getOptions(),
                method: "POST",
                body: JSON.stringify(body)
            }
        ).then(this.handleResponse);
    }
}

export function getError(e: any): {status: number, response: string} {
    try {
        const j = JSON.parse(e.message);
        if (!("status" in j) || !("response" in j)) {
            return {status: 0, response: e};
        }
        return {
            status: j.status,
            response: j.response
        }
    } catch {
        return {status: 0, response: e}
    }
}
